

// import React from "react";
// // import Banner1 from "../../assets/images/Teddy_Dog/Dog_6.png";
// // import Banner2 from "../../assets/images/Teddy_Animal/Animal1.png";
// // import Banner3 from "../../assets/images/Teddy_Panda/panda6.png";


// function CategorySelector(props) {

//   return (
//     <div className="banner">
// kjjkjk
//     </div>
//   );
// }

// export default CategorySelector;

import React, { Component } from "react";
import axios from "axios";
class CategorySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null
    };
  }
  onImageChange = () => {
    const formData = new FormData();
    formData.append("myFile", this.state.selectedFile);

    console.log(this.state.selectedFile);
    axios.post("/api/uploadfile", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }); //I need to change this line
  };


  render() {
    return (
      <div>
        <div>
          <div>
            <img src={this.state.image} />
            <h1>Select Image</h1>
            <input type="file" name="myImage" onChange={this.onImageChange} />
          </div>
        </div>
      </div>
    );
  }
}
export default CategorySelector;

