import React, { Component } from "react";
import PropTypes from "prop-types";
import DEALOFWEEK from "../../assets/images/newImages/Img1.png";
import "./Advertisement.scss"; // Import CSS for styling

class Advertisement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="advertisement-container" data-aos="fade-up">
        <div className="hero-banner">
          <img src={DEALOFWEEK} alt="Deal of the Week" className="banner-img" />
          <h1 className="hero-title">Customize Your Own T-Shirt</h1>
        </div>

        <div className="container content-wrapper">
          {/* New wrapper class applied */}
         
            <section className="intro-section">
              <h3 className="main-heading">
                Customized T-Shirts: Design Your Own T-shirts
              </h3>
              <p className="intro-text">
                Since our business started, we’ve focused on supplying business
                uniforms such as restaurant and hotel T-shirts, as well as other
                hospitality uniforms with high-quality, budget-friendly options.
                Today, we’re proud to work with a diverse group of clients across
                India, including top names in the restaurant, healthcare,
                hospitality, and retail industries.
              </p>
            </section>

            <section className="customization-process">
              <h4 className="subheading">How to Customize Your T-Shirts:</h4>
              <ul className="process-list">
                <li>1. Choose your product.</li>
                <li>2. Go to the custom design section.</li>
                <li>3. Add your logo, message, or graphics.</li>
                <li>4. Get free design support from our team.</li>
                <li>5. Place your order and enjoy fast delivery.</li>
              </ul>
            </section>

            <section className="value-proposition">
              <h3 className="subheading">Why Choose Us?</h3>
              <p className="value-text">
                We offer bulk discounts, fast delivery, and high-quality
                customizable uniforms for your business. Whether you need
                T-shirts, jackets, or polos, our easy-to-use online designer
                platform helps you create the perfect branded look for your team.
              </p>
            </section>

            <section className="featured-section">
              <h3 className="main-heading">Custom T-Shirts for All Needs</h3>
              <p className="intro-text">
                Whether for corporate branding, promotional events, or casual wear,
                we offer high-quality custom T-shirts that are both stylish and
                durable. Our easy customization options ensure a professional and
                polished look.
              </p>
            </section>
          </div>
        </div>
    
    );
  }
}

Advertisement.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Advertisement;
