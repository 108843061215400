// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.fade.right.modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  right: 0;
  transform: translate3d(0%, 0, 0);
}

.fade.right.modal .modal-content {
  height: 100%;
  overflow-y: auto;
}

.fade.right.modal .modal-body {
  padding: 15px 15px 80px;
}

/*Right*/
.fade.right.modal {
  right: -320px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.fade.right.modal.show {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.fade.right.modal .modal-content {
  border-radius: 0;
  border: none;
}

.shopping--cart--container {
  margin-top: 25vh;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.shopping--cart--item {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.cart--item--img {
  text-align: center;
}
.cart--item--img > img {
  max-height: 150px;
  max-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeCartView/style.css"],"names":[],"mappings":";;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,QAAQ;EAIR,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA,QAAQ;AACR;EACE,aAAa;EAIb,oDAAoD;AACtD;;AAEA;EACE,QAAQ;AACV;;AAEA,4BAA4B;AAC5B;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,2CAA2C;EAC3C,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["\n\n.fade.right.modal .modal-dialog {\n  position: fixed;\n  margin: auto;\n  width: 320px;\n  height: 100%;\n  right: 0;\n  -webkit-transform: translate3d(0%, 0, 0);\n  -ms-transform: translate3d(0%, 0, 0);\n  -o-transform: translate3d(0%, 0, 0);\n  transform: translate3d(0%, 0, 0);\n}\n\n.fade.right.modal .modal-content {\n  height: 100%;\n  overflow-y: auto;\n}\n\n.fade.right.modal .modal-body {\n  padding: 15px 15px 80px;\n}\n\n/*Right*/\n.fade.right.modal {\n  right: -320px;\n  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;\n  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;\n  -o-transition: opacity 0.3s linear, right 0.3s ease-out;\n  transition: opacity 0.3s linear, right 0.3s ease-out;\n}\n\n.fade.right.modal.show {\n  right: 0;\n}\n\n/* ----- MODAL STYLE ----- */\n.fade.right.modal .modal-content {\n  border-radius: 0;\n  border: none;\n}\n\n.shopping--cart--container {\n  margin-top: 25vh;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n  transition: 0.3s;\n  border-radius: 10px;\n  padding-left: 30px;\n  padding-right: 30px;\n  padding-bottom: 30px;\n}\n\n.shopping--cart--item {\n  margin-top: 20px;\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid #eeeeee;\n}\n\n.cart--item--img {\n  text-align: center;\n}\n.cart--item--img > img {\n  max-height: 150px;\n  max-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
