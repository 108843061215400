import React, { Component } from "react";
import RegistrationForm from '../../../components/LoginRegisterModal/RegisterForm'

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalShow: false,
            login: true
        };
    }
    render() {
        return (
            <div className="form-body">
                <div className="container about-container">
                    <div className="row">
                        <div className="form-holder col-md-6">
                            <div className="form-content">
                                <RegistrationForm/>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <h1>Contact Information</h1>
                            <div>

                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <div class="elementor-icon-list-icon col-md-1">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </div>
                                            <p class="elementor-icon-list-text col-md-11"><span>Wingle Pvt.Ltd.</span>
                                                <br />B204, Disha park West
                                                <br />Balagere Road, Bangalore</p>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="tel:+91%2080%204954%206547" className="elementor-icon-list-item">

                                                <div class="elementor-icon-list-icon col-md-1">
                                                    <i aria-hidden="true" class="fas fa-phone-volume"></i></div>
                                                <p class="elementor-icon-list-text col-md-9">+91 99 0023 6375</p>
                                                <p class="elementor-icon-list-text col-md-6">+91 90 3633 5101</p>
                                            </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <a href="mailto:contact@wingle.in" className="elementor-icon-list-item">

                                                <span class="elementor-icon-list-icon col-md-1">
                                                    <i aria-hidden="true" class="far fa-envelope"></i></span>
                                                <p class="elementor-icon-list-text col-md-6">contact@wingle.in</p>
                                                <p class="elementor-icon-list-text col-md-6">wingle.fabrication@gmail.com</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-51f890db" data-id="51f890db" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-270d8505 elementor-widget elementor-widget-heading" data-id="270d8505" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">contact information</h2>		</div>
                                    </div>
                                    <div class="elementor-element elementor-element-6c043be6 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="6c043be6" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items">
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon">
                                                        <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                    </span>
                                                    <span class="elementor-icon-list-text"><b>VProPle Solutions Pvt.Ltd.</b><br />  WeWork, Vaisnavi Signature,<br /> No. 78/9, Outer Ring Road, <br /> Bellandur Village, Varthur Hobli, <br /> Bengaluru - 560103, Karnataka, India</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <a href="tel:+91%2080%204954%206547">

                                                        <span class="elementor-icon-list-icon">
                                                            <i aria-hidden="true" class="fas fa-phone-volume"></i>						</span>
                                                        <span class="elementor-icon-list-text">+91 80 4954 6547</span>
                                                    </a>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <a href="mailto:sales@vprople.com">

                                                        <span class="elementor-icon-list-icon">
                                                            <i aria-hidden="true" class="far fa-envelope"></i>						</span>
                                                        <span class="elementor-icon-list-text">sales@vprople.com</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4011dd4e elementor-widget elementor-widget-heading" data-id="4011dd4e" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">connect us in social media</h2>		</div>
                                    </div>
                                    <div class="elementor-element elementor-element-194cb10f e-grid-align-left elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="194cb10f" data-element_type="widget" data-widget_type="social-icons.default">
                                        <div class="elementor-widget-container">
                                            <span class="elementor-grid-item">
                                                <a class="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-1365649" href="https://www.facebook.com/VProPle" target="_blank">
                                                    <span class="elementor-screen-only">Facebook</span>
                                                    <i class="fab fa-facebook"></i>					</a>
                                            </span>
                                            <span class="elementor-grid-item">
                                                <a class="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-4738ae8" href="https://www.linkedin.com/company/vprople" target="_blank">
                                                    <span class="elementor-screen-only">Linkedin</span>
                                                    <i class="fab fa-linkedin"></i>					</a>
                                            </span>
                                            <span class="elementor-grid-item">
                                                <a class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-ed231a7" href="https://twitter.com/VProp1e" target="_blank">
                                                    <span class="elementor-screen-only">Twitter</span>
                                                    <i class="fab fa-twitter"></i>					</a>
                                            </span>
                                            <span class="elementor-grid-item">
                                                <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-fc04c86" href="https://www.instagram.com/vprople/" target="_blank">
                                                    <span class="elementor-screen-only">Instagram</span>
                                                    <i class="fab fa-instagram"></i>					</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}
export default ContactUs
