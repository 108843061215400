
import React, { Component } from "react";
import BrandWarrenty from "../../../assets/images/shippinglogo/brand_warrenty.png";
import FreeShipping from "../../../assets/images/shippinglogo/free_shipping.png";
import GenuineProduct from "../../../assets/images/shippinglogo/genuine_product.png";
import SecurePayment from "../../../assets/images/shippinglogo/secure_payment.png";


class ContactBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalShow: false,
            login: true
        };
    }
    data =  [
                { img: FreeShipping, text: 'Free Shipping' }, 
                { img: SecurePayment, text: 'Secure Payment' }, 
                { img: BrandWarrenty, text: 'Quality Warrenty' }, 
                { img: GenuineProduct, text: 'Genuine Products' }
            ]

    render() {
        const { products, departments } = this.props;
        return (
            <>
                <div className="container whyFromUs-width">
                    <div className="row">
                        <h1 className="text-center col-md-12">Why to buy from us</h1>
                        <div className="col-md-12 d-flex mt-4">
                            {this.data.map((item) => (
                                <div className="col-md-3 img-width">
                                    <img src={item.img} className="whyFromUsImg"/>
                                    <div className="textClass">{item.text}</div>
                                </div>
                                ))
                            }
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default ContactBanner;
