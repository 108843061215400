import API from "../axios/API";
import Auth from "../modules/Auth";

const BASE_URL= "http://16.171.200.162:9099/"

export const login = async (email, password) => {
  const body = {
    credential: {
      email: email,
      password: password
    }
  };
  return await API({
    method: "POST",
    url: `${BASE_URL}wingle/login`,
    data: body
  }).then(res => {
    Auth.setUserToken(res.data.user_token);
    return res;
  });
};

export const LoginService = async (option) => {
  const res = fetch(`${BASE_URL}wingle/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(option),
   })
   .then((response) => response.json())
   .then((responseJson) => {
    debugger
     return responseJson.movies;
   })
   .catch((error) => {
     console.error(error);
   });
};
// export const RegisterationService = async (option) => {
//   const res = fetch(`${BASE_URL}wingle/registration`, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(option),
//    })
//    .then((response) => response.json())
//    .then((responseJson) => {
//     debugger
//      return responseJson.movies;
//    })
//    .catch((error) => {
//      console.error(error);
//    });
// };
export async function RegisterationService(endpoint, option, headers = {}) {

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      body: JSON.stringify(option),
      headers: {
        'Content-Type': 'application/json',
        ...headers // Merge custom headers with default headers
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}



export async function BlogService(endpoint, headers = {}) {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers // Merge custom headers with default headers
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}
