// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container .blogs-header {
  color: #7a7aa4;
  padding-top: 3%;
}
.container .blogs-background-img {
  background-size: cover;
  background-position: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/Blog/style/stylesheet.scss"],"names":[],"mappings":"AAEC;EACG,cAAA;EACA,eAAA;AADJ;AAGA;EAEI,sBAAA;EACA,2BAAA;EACA,WAAA;AAFJ","sourcesContent":[" .container {\n\n .blogs-header{\n    color: #7a7aa4;\n    padding-top: 3%;\n}\n.blogs-background-img {\n    // background-image: url('../../../assets/images//Teddy_Dog/Dog_6.png');\n    background-size: cover;\n    background-position: center;\n    width: 100%;\n}\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
