

import React, { Component } from "react";
import jumpTo from "../../modules/Navigation";
import Validator from "../../utils/Validator";
import { DEFAULT_RULE, EMAIL_RULE } from "../../utils/Validator/rule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRegister } from "../../redux/actions/RegisterAction";
import LoadingButton from "../LoadingButton";
import { RegisterationService } from '../../ServerRequest/index'

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      phoneNo: '',
      response: {}
    };
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  isNumberKey = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // setData =(response)=>{
  //   debugger
  //   // props.onHide()
  // }
  setError =(error) =>{
    debugger
  }
  handleSubmit = (async (e) => {
    const { name, email, password, phoneNo } = this.state;

    // e.preventDefault();
    let data = {
        userName: name,
        email: email,
        phone: phoneNo,
        pwd: password
    }
    if (!Validator(name, DEFAULT_RULE)) {
      console.log("Name Error");
      return;
    }
    if (!Validator(email, EMAIL_RULE)) {
      console.log("email Error");
      return;
    }
    if (!Validator(password, DEFAULT_RULE)) {
      console.log("Password Error");
      return;
    }
    if (!Validator(phoneNo, DEFAULT_RULE)) {
      console.log("phoneNo Error");
      return;
    }
    this.setState({ loading: true });
      RegisterationService('wingle/registration', data)
        .then(res => {
          this.setState({response: res})
        })
        .catch(error => {
          this.setError(error.message);
        });
    
});
  render() {
    return (
      <div className="form-body">
        <div className="container about-container">
          <div className="row modal-row">
            <div className="form-items">
              <h3>Register Today</h3>
              <p>Fill in the data below.</p>
              <form className="requires-validation" novalidate onSubmit={(e)=>this.handleSubmit} >
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name "
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    autoComplete="false"
                  />
                  <i className="fa fa-user"></i>
                </div>

                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email "
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    autoComplete="false"
                  />
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone No "
                    id="phoneNo"
                    name="phoneNo"
                    value={this.state.phoneNo}
                    onChange={this.handleChange}
                    autoComplete="false"
                    onkeypress={this.isNumberKey}
                  />
                  <i className="fa fa-phone"></i>
                </div>
                <div className="form-group log-status">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    id="Passwod"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    autoComplete="false"
                  />
                  <i className="fa fa-lock"></i>
                </div>
                <span className="alert">Invalid Credentials</span>
                <LoadingButton
                  type="button"
                  className="log-btn"
                  loading={this.state.loading}
                  onClick={() => this.handleSubmit()}
                >
                  Register
                </LoadingButton>
                <div
                  onClick={this.props.loginClicked}
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#c4c4c4",
                    cursor: "pointer"
                  }}
                >
                  Already have an account ? Please login.
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisterForm.propTypes = {
  loginClicked: PropTypes.func
};

const mapDispatchToProps = {
  userRegister
};
const mapStoreToProps = state => ({
  register_loading: state.register.register_loading,
  register_error: state.register.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(RegisterForm);
