
import React, { Component } from "react";
import BlogContent from "./BlogContent"

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true
    };
  }



  render() {
    return (
      <div>
      <BlogContent/>
      </div>
    );
  }
}

export default Blog;
