
import React, { Component } from "react";
import ContactBanner from './ContactBanner'
import ContactUs from './ContactUs'
import AboutUs from './AboutUs'
import "./style/stylesheet.css"

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true
    };
  }



  render() {
    return (
      <div>
        <ContactBanner/>
        <ContactUs/>
        <AboutUs/>
      </div>
    );
  }
}

export default Contact;
