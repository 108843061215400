import React from "react";
import BackgroundImage1 from "../../assets/images/Banner/tshirt_banner.png";
import BackgroundImage2 from "../../assets/images/Banner/tshirt_banner4.png";
import BackgroundImage3 from "../../assets/images/Banner/tshirt_banner2.jpg";
import { Carousel } from "react-bootstrap";

function HomeBanner(props) {
  let data = [
    {
      img: BackgroundImage1,
    },
    {
      img: BackgroundImage2,
    },
    {
      img: BackgroundImage3,
    }
  ];

  return (
    <Carousel>
      {data.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <div
              className="d-block w-100 main_slider"
              style={{
                backgroundImage: `url(${item.img})`,
                backgroundSize: 'cover',  // Ensures the image covers the entire space
                backgroundPosition: 'center center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents repeating the image
                height: '50vh', // Makes the carousel take full viewport height
              }}
            >
              {/* Add content on top of the image */}
              <div className="carousel-content">
                <h6>{item.helpText}</h6>
                <h1>{item.helpText2}</h1>
                <div className="red_button shop_now_button">
                  <a href="#">{item.buttonText}</a>
                </div>
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default HomeBanner;
