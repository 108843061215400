import React, { Component } from "react";
import Banner1 from "../../../assets/images/Contact.png";

class ContactBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalShow: false,
            login: true
        };
    }

    render() {
        const { products, departments } = this.props;
        return (
            <>
                <div className="banner" style={{ position: "relative", width: "100%", height: "35vh" }}>
                    <div
                        className="banner_item"
                        style={{
                            backgroundImage: `url(${Banner1})`,
                            backgroundSize: "cover",   // Make image cover the container
                            backgroundPosition: "center", // Center the image
                            width: "100%",   // Ensure the image takes full width
                            height: "35vh",
                            position: "absolute"  // To cover the parent container fully
                        }}
                        data-aos="fade-right"
                    >
                        <div className="elementor-heading">
                            <h1 className="elementor-heading-title">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ContactBanner;
