import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeCartView from "../HomeCartView";
import MobileMenu from "../MobileMenu";
import device, { size } from "../../modules/mediaQuery";
import MediaQuery from "react-responsive";
import logo from "../../assets/images/wingle-logo/png/logo-no-background.png";
import PropTypes from "prop-types";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      activeclass: false,
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.cart).length < 1) {
      this.props.getCartByUserId();
    }
  }

  showHideModal = () => {
    this.setState(prevState => ({ modalShow: !prevState.modalShow }));
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ activeclass: !prevState.activeclass }));
  };

  renderCartIcon = () => {
    const { cart } = this.props;
    return (
      <li className="checkout">
        <a href="#" onClick={this.showHideModal}>
          <i className="fas fa-shopping-bag"></i>
          {cart.totalQty > 0 && (
            <span id="checkout_items" className="checkout_items">
              {cart.totalQty}
            </span>
          )}
        </a>
      </li>
    );
  };

  render() {
    const { departments, cart } = this.props;

    return (
      <div className="main_nav_container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-right">
              <div className="logo_container">
                <img src={logo} className="logo-img" alt="Wingle Logo" />
              </div>
              <nav className="navbar">
                <ul className="navbar_menu">
                  <li>
                    <Link to="/home">home</Link>
                  </li>
                  <li className="mega-drop-down">
                    <a href="#">
                      shop <i className="fa fa-angle-down"></i>
                    </a>
                  </li>
                  <li>
                    <Link to="/contact">contact</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/admin">Admin</Link>
                  </li>
                </ul>
                <ul className="navbar_user">
                  <li>
                    <a href="#">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </a>
                  </li>
                  {this.renderCartIcon()}
                </ul>
                <div
                  className="hamburger_container"
                  onClick={this.toggleMobileMenu}
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <MediaQuery query={device.max.tabletL}>
          <MobileMenu
            activeClass={this.state.activeclass}
            onClose={this.toggleMobileMenu}
          />
        </MediaQuery>
        {this.state.modalShow && (
          <HomeCartView
            cart={cart}
            show={this.state.modalShow}
            onHide={this.showHideModal}
          />
        )}
      </div>
    );
  }
}

NavBar.propTypes = {
  cart: PropTypes.object.isRequired,
  departments: PropTypes.array,
  getCartByUserId: PropTypes.func.isRequired,
};

export default NavBar;
