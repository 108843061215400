

// import React from "react";
import Banner1 from "../../../assets/images/tshirt/images/Product1/Product1-image1.jpg";
import Banner2 from "../../../assets/images/tshirt/images/Product2/Product2-image1.jpg";
import Banner3 from "../../../assets/images/tshirt/images/Product3/Product3-image1.jpeg";
import Banner4 from "../../../assets/images/tshirt/images/Product4/Product4-image1.jpeg";
// import { Link } from "react-router-dom";
// import CategorySelector from "../../CategorySelector/index"

// // import {useProgressiveImage} from '../../hooks/imgLoader'

// function CategoryBanner(props) {
//   let data =
//     [{ img: Banner1, text: '', price: '1000', name: "Cute Puppy", type: "puppy", id:"001" },
//     { img: Banner2, text: '', price: '850', name: "Friendly Animal", type: "Animal", id:"002" },
//     { img: Banner3, text: '', price: '1200', name: "Cool Panda",type: "Panda", id:"003"  }
//     ]

//   // const loaded = (source)=>useProgressiveImage(source)
//   return (
//     <div className="banner">
//       <div className="container">
//         <div className="row">
//           {data &&
//             data.map((item, index) => {
//               return (
//                 <div className="col-md-4 category-banner" key={index}>
//                   <div className="category-card">
//                   <Link to={`/categorySelector`}
//                     className="banner_item align-items-center"
//                     data-aos="fade-right">
//                       <img className="category-img" src={item.img}>
//                         {/* <Link to="/categorySelector">Category</Link> */}
//                       </img>

//                   </Link>
//                   <div className="text-center">{item.name}</div>
//                   <div className="text-center">{item.price}</div>
//                   </div>
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CategoryBanner;

import React from 'react';

// Sample product data
const products = [
  {
    id: "win-polo-black-1",
    handle: 'y2k-square-neck-crop-top',
    title: 'Win Men Abstract Polo Neck Cotton Blend Black T-Shirt',
    price: 'Rs. 899.00',
    imageUrl: Banner1,
    alt: 'Y2K Square Neck Crop Top Image',
  },
  {
    id: "win-polo-blue-1",
    handle: 'y2k-high-waist-jeans',
    title: 'Win Men Embroidered Mandarin Collar Cotton Blend Multicolor T-Shirt',
    price: 'Rs. 799.00',
    imageUrl: Banner2,
    alt: 'Y2K High Waist Jeans Image',
  },
  {
    id: "win-polo-multi-1",
    handle: 'vintage-leather-jacket',
    title: 'Win Men Abstract Polo Neck Cotton Blend White T-Shirt',
    price: 'Rs. 799.00',
    imageUrl: Banner3,
    alt: 'Vintage Leather Jacket Image',
  },
  {
    id: "win-round-multi-1",
    handle: 'vintage-leather-jacket',
    title: 'Win Men Striped Round Neck Polyester Multicolor T-Shirt',
    price: 'Rs. 699.00',
    imageUrl: Banner4,
    alt: 'Vintage Leather Jacket Image',
  },
  {
    id: "win-polo-black-2",
    handle: 'y2k-square-neck-crop-top',
    title: 'Win Men Abstract Polo Neck Cotton Blend Black T-Shirt',
    price: 'Rs. 899.00',
    imageUrl: Banner1,
    alt: 'Y2K Square Neck Crop Top Image',
  },
];

const ProductCard = () => {
  return (
    <div className="product-grid">
      {products.map((product) => (
        <div key={product.id} className="product-grid-item" data-product-handle={product.handle} data-product-id={product.id}>
          <div className="grid-item__content">
            <div className="grid-product__actions">
              <div className="quick-shop">
                <div className="tool-tip-trigger" data-tool-tip="QuickShop" data-tool-tip-classes="quick-shop-modal">
                  <span className="tool-tip-trigger__content" data-tool-tip-trigger-content=""></span>
                  <button
                    type="button"
                    className="btn btn--circle btn--icon quick-product__btn js-modal-open-quick-modal-7095673847942 small--hide"
                    title="Quick shop"
                    data-handle={product.handle}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" className="icon icon-search icon--line" viewBox="0 0 64 64" width="20" height="20">
                      <path d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58M54 54 41.94 42" stroke-miterlimit="10" fill="none" stroke="currentColor"></path>
                    </svg>
                    <span className="icon__fallback-text visually-hidden">Quick shop</span>
                  </button>
                </div>
              </div>
            </div>
            <a href={`/collections/trending-2/products/${product.handle}`} className="grid-item__link">
              <div className="grid-product__image-wrap">
                <div className="grid__image-ratio grid__image-ratio--square">
                  <img
                    src={product.imageUrl}
                    alt={product.alt}
                    srcSet={`${product.imageUrl}?width=160 160w, ${product.imageUrl}?width=200 200w, ${product.imageUrl}?width=280 280w, ${product.imageUrl}?width=360 360w, ${product.imageUrl}?width=540 540w, ${product.imageUrl}?width=720 720w, ${product.imageUrl}?width=900 900w`}
                    width="900"
                    height="900"
                    loading="lazy"
                    className="image-style--square image-element"
                    sizes="30vw"
                  />
                </div>
              </div>
            </a>
            <div className="grid-item__meta">
              <div className="grid-item__meta-main">
                <div className="grid-product__title">{product.title}</div>
              </div>
              <div className="grid-item__meta-secondary">
                <div className="grid-product__price">
                  <span className="grid-product__price--current">
                    <span aria-hidden="true">{product.price}</span>
                    <span className="visually-hidden">{product.price}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCard;

