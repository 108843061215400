

import React, { useState, useEffect } from "react";
import { BlogService } from '../../ServerRequest/index'
import "./style/stylesheet.scss"

function BlogContent(props) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const fetchDataFromApi = () => {
    BlogService('wingle/fetchBlog')
      .then(data => {
        setData(data);
      })
      .catch(error => {
        setError(error.message);
      });
  };
  const BlogPost = ({ title, content, createdBy, date }) => (
        <div className="col-md-8 offset-md-2">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title">{title}</h2>
              <div className="blogs-background-img">
              <p className="card-text">{content}</p>
              <p className="author">{createdBy}</p>
              <p className="card-text"><small className="text-muted">Published on March 1, 2024</small></p>
              </div>
            </div>
          </div>
        </div>
  );
  return (
    <div className="container">
      <h2 className="blogs-header text-center">Blogs</h2>
      {data?.map(post => (
        <BlogPost
          key={post.blogId}
          title={post.blogName}
          content={post.blogs}
          createdBy={post.createdBy}
        />
      ))}
    </div>
  );
}

export default BlogContent;