import React, { useState } from "react";

const AboutUs = () => {
  const [data, setData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [login, setLogin] = useState(true);

  return (
    <div className="container">
      <div className="row">
        <h1>Who We Are</h1>

        <p className="aboutPara">
          At Wingle, we're committed to helping businesses leave a lasting impression through premium custom T-shirts. Our journey began with a group of visionary entrepreneurs, all driven by a common goal: to provide businesses with top-notch apparel that blends style, durability, and purpose. Whether you’re outfitting your team, organizing an event, or elevating your brand's visibility, we are here to deliver.
          <br />
          <br />
          We understand the power of great custom apparel. It’s more than just clothing; it’s a way to elevate your brand, enhance team spirit, and forge strong connections with your audience. From the initial idea to the final product, we offer full customization options — from design and fabric to every small detail — ensuring your T-shirts are exactly what you need.
        </p>

        <section id="our-values">
          <h2>Our Core Principles</h2>
          <div className="values-container">
            <div className="value-item">
              <h3>Innovation</h3>
              <p>
                We’re always looking for new ways to innovate and stay ahead of trends in the custom apparel world. Our commitment is to ensure that our products are always fresh, relevant, and push the boundaries of creativity.
              </p>
            </div>
            <div className="value-item">
              <h3>Uncompromising Quality</h3>
              <p>
                Quality is at the heart of everything we do. We hold ourselves to the highest standards, ensuring each T-shirt is crafted with care, offering exceptional comfort and long-lasting durability.
              </p>
            </div>
            <div className="value-item">
              <h3>Client-First Approach</h3>
              <p>
                Our clients are the center of everything we do. We go the extra mile to offer personalized service and custom designs that go beyond expectations, every single time.
              </p>
            </div>
          </div>
        </section>

        <section id="our-mission">
          <h2>Our Purpose</h2>
          <p>
            At Wingle, our goal is to empower businesses with custom T-shirts that represent their brand with quality and flair. We're dedicated to offering flexible, innovative solutions that align with each business's unique vision, ensuring every product is a reflection of its identity.
          </p>
          <p>
            Our focus is on building strong, lasting relationships with our clients by delivering unparalleled quality, exceptional service, and reliable, on-time delivery. We want to help your business stand out and succeed with custom apparel that makes an impact.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
